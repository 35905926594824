*{
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
}
:root
{
  --yellow: #F5C32C;
    --orange : #FCA61F;
    --black : #212121;
    --gray : #7c7d7d;
    --blueCard : #DDF8FE;
    --purple: rgb(238 210 255);
    --boxShadow : 0px 19px 60px rgb(0 0 0 / 8%);
    --orangeCard: rgba(252, 166, 31, 0.45);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
    --bg:linear-gradient(to bottom right,#0f1012,#181b90);
    --main: #1c72b8;
}
.btn{
    background-color: transparent;
    border: 1px solid white;
    height: 36px;
    line-height: 35px;
    font-size: 12px;
    min-width: 100px;
    padding: 0 18px;
    text-align: center;
    font-weight: 600;
    border-radius: 40px;
    cursor: pointer;
    display: inline-block;
    transition: all .3s;
    text-transform: uppercase;
    color: white;
    margin: 5px;
    transition: .3s all ease;
}

.btn:hover , .btn>a:hover{
    background-color: white;
    color: #1c72b8;

}
.circle-btn{
    width: 70px;
    height: 70px;
    font-size: 9px;
    line-height: 70px;
    color: #fff;
    text-align: center;
    border: 1px solid #fff;
    border-radius: 50%;
    text-transform: uppercase;
    background-color: transparent;
    letter-spacing: 1px;
    display: block;
    transition: .3s all ease;
}
.circle-btn:hover{
    background-color: white;
    color: #1c72b8;
}

.viewing {
    color: #212121;
    font-weight: 300;
    display: inline-block;
    margin-top: 5%;
}

.viewing-span>a {
    color: #1c72b8;
    cursor: pointer;
    text-decoration: none;
}
/* 
linear-gradient(to bottom right,#0f1012,#181b90) 

color: #1c72b8; */