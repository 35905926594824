.contact-wrappe{
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 5%;
}
.contactBg{
    position: absolute;
    top: 20%;
    left: 10%;
}
.contactBg>img{
    width:90%;
}
.contact-header>h5{
    width: 90%;
    margin-bottom: 5%;
}
.contact-forms{
    width: 100%;
    height: auto;
    display: flex;
}
.cf-box{
    width: 50%;
    border-radius: 10px;
    margin: 0rem 1rem 0rem 0rem;
    border: 1px solid var(--main);
}
.cf-heading{
    margin: 1rem;
    widows: 90%;
}
.cf-heading>h1 {
    color: #1c72b8;
    font-size: 26px;
    letter-spacing: 1px;
    line-height: 1;
    font-weight: bold;
    margin-bottom: 1%;
}
.abc{
    font-size: 19px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 1;
}
.cf-heading>h2{
    color: #1c72b8;
    font-size: 24px;
    letter-spacing: 1px;
    line-height: 1;
    font-weight: bold;

}
.cf-items>form{
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
}
.cf-items>form>input{
    width: 90%;
    height: 35px;
    line-height: 35px;
    padding: 0;
    font-size: 20px;
    font-weight: 400;
    outline: none;
    margin: 0.5rem;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid var(--main);
    padding: 0.5rem;
    color: black;

}
.cf-items>form>select{
    width: 90%;
    height: 50px;
    line-height: 35px;
    padding: 0;
    font-size: 20px;
    font-weight: 400;
    outline: none;
    margin: 0.5rem;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid var(--main);
    padding: 0.5rem;
    color: black;
}
::placeholder{
    color: var(--main);
}
.contact-btn{
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin: 0.5rem;
}
.contact-btn>:nth-of-type(1) , .contact-btn>:nth-of-type(2){
    border: 1px solid var(--main);
    color: var(--main);
}
.contact-btn>:nth-of-type(1):hover, 
.contact-btn>:nth-of-type(2):hover{
    background-color: var(--main);
    color: white;

}