.events-wrapper {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 5%;
}

.eventbg {
    position: absolute;
    top: 20%;
    left: 10%;
}

.eventbg>img {
    width: 90%;
}

.event-header {
    margin-bottom: 3%;
}

.event-header>h5 {
    width: 80%;
}

.event-boxs {
    width: 100%;
    height: auto;
    display: flex;
}

.event-box {
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    width: 33.3%;
    border-radius: 10px;
    margin: 1rem 1rem 1rem 0rem;
}

.eb-img>img {
    width: 100%;
}

.eb-title {
    margin: 1rem;
    width: 90%;
}

.eb-title>h2 {
    font-size: 21px;
    letter-spacing: 1px;
    color: var(--black);
    font-weight: 550;
}

.eb-desc {
    margin: 1rem;
}

.eb-desc1 {
    margin: 0.5rem 0rem;
}

.eb-desc1>h4,
.eb-desc2>h4 {
    font-size: 18px;
    color: #1c72b8;
}

.eb-desc1>h6,
.eb-desc2>h6 {
    font-size: 16px;
    line-height: 28px;
    font-weight: 300;
    color: var(--gray);
}