.menuBarHome{
    padding: 3% 0 0 0;
    width: 10%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: fixed;
}
.menuLogo1{
    margin-bottom: 150%;
}
.main_MM>h1 {
    color: white;
    text-transform: uppercase;
    font-family: 'Eurostile Extended', sans-serif;
    font-size: 11px;
    letter-spacing: 2px;
    margin-bottom: 5px;
    font-weight: 200;
}

.main_MM>span {
    color: white;
    font-weight: 100;
}

.main_MM>span:hover {
    cursor: pointer;
}
