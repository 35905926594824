.about-wrapper {
    width: 90%;
    margin-left: 10%;
}
.about-header>h1>img{
    position: absolute;
    left: 0%;
    top: -90%;
    width: 100%;
}
.about-header>h1 {
    line-height: 1.1;
    color: #212121;
    font-size: 3rem;
    font-weight: bold;
    margin-top: 10%;
    margin-bottom: 2%;
    position: relative;
}

.about-header>h5 {
    font-size: 24px;
    line-height: 34px;
    display: block;
    padding-bottom: 20px;
    font-weight: 200;
    color: #7c7d7d;
    width: 60%;
    margin-left: 0;
}

.about-btn1 {
    width: fit-content;
    height: auto;   
    background-color: white;
    color: #1c72b8;
    border: 1px solid #1c72b8;;
    transition: all .3s;
}

.about-btn1:hover {
    background-color: #1c72b8;
    color: white;
}

.about-btn2 {
    width: fit-content;
    height: auto;
    background-color: #1c72b8;
    color: white;
    border: 1px solid #1c72b8;
}

.about-box1 {
    background: linear-gradient(to bottom right, #0f1012, #181b90);
    width: 90%;
    height: 90vh;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    margin-top: 5%;
    margin-bottom: 200px;
    position: relative;
}

.about-box1>img {
    position: absolute;
    width: 100%;
    height: 100%;
}

.about-b-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 460px;
    z-index: 99;
}

.about-b-title>h1,
.about-b-title>h1>span {
    line-height: 1;
    font-size: 54px;
    letter-spacing: 2px;
    color: white;
    font-weight: bold;
    margin-bottom: 10%;
}

.about-b-title>h6 {
    font-size: 20px;
    line-height: 34px;
    color: white;
    font-weight: 300;
    width: 90%;
}

.viewing-icon {
    background: #185291;
    z-index: 100;
    font-size: 0;
    border-radius: 50px;
    padding: 6px 30px;
    width: 40%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 5%;
}

.viewing-icon>img {
    width: 50px;
    z-index: 100;
}

.a-experience {
    background: linear-gradient(to bottom right, #0f1012, #181b90);
    width: 100%;
    color: white;
    padding: 100px 0;
    margin-bottom: 7%;
}

.experience-wrapper {
    margin-left: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.exper-l {
    width: 45%;
    flex: 2;
}

.exper-l>h1 {
    font-size: 36px;
    letter-spacing: 1px;
    line-height: 1.1;
    padding: 10px 0;
}

.exper-l>h6 {
    font-size: 20px;
    line-height: 34px;
    font-weight: 300;
    width: 80%;
    margin-left: 0;

}

.exper-r {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    margin-right: 5%;
}

.exper-circle1 {
    border: 1px solid white;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.circle>h1 {
    font-size: 50px;
    letter-spacing: 3px;
    line-height: 1;
    padding-bottom: 10px;
}

.circle>h3 {
    color: #609ccd;
    font-size: 20px;
    line-height: 1.1;
    font-weight: 300;
}

.edge {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 7%;
}

.edge-heading>h1 {
    font-size: 36px;
    letter-spacing: 1px;
    font-weight: bold;
    margin-bottom: 30%;
}

.edge-box {
    width: 100%;
    height: auto;
    border-radius: 10px;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    padding: 2vw 5%;
    margin: 1rem;
}

.edge-box>h2 {
    font-size: 26px;
    letter-spacing: 1px;
    line-height: 1;
    color: #212121;
    font-weight: bold;
    margin: 0.5rem 0rem;
}

.edge-box>h6 {
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    color: var(--gray);
    width: 110%;
}

.edge-top {
    display: flex;
    margin-left: 8%;
    margin-right: 7%;
}

.edge-down {
    display: flex;
    margin-left: 8%;
    margin-right: 7%;
}

.directors {
    background: var(--bg);
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 5%;
}

.director-heading {
    color: white;
    margin: 1rem;
}

.director-heading>h1 {
    font-size: 36px;
    letter-spacing: 1px;
    line-height: 1.1;
    font-weight: bold;
    margin: 1rem;
}

.dir-box {
    display: flex;
    justify-content: space-between;
    margin-left: 10%;
    margin-right: 10%;
}

.director-card {
    border-radius: 10px;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2vw 3%;
    margin: 0.5rem;
}

.dir-img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    border: 1px solid black;
    margin-bottom: 12%;
}

.dir-img>img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-size: cover;
}

.dir-title>h2 {
    font-size: 26px;
    letter-spacing: 1px;
    color: #212121;
    line-height: 1;
    margin: 0.5rem;
    width: 100%;
    margin-bottom: 5%;
}

.dir-title>h4 {
    font-size: 16px;
    line-height: 28px;
    font-weight: 300;
    color: var(--gray);
    width: 100%;
    margin-bottom: 30%;
}

.dir-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dir-btn {
    border: 1px solid #1c72b8;
    color: #1c72b8;
}

.dir-btn:hover {
    background-color: #1c72b8;
    color: white;
}

.dir-link {
    background-color: transparent;
    color: #1c72b8;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 15px;
    margin-right: -60%;
}

.dir-add {
    margin: 2rem 1rem;
}

.managment-team {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.team-heading {
    color: var(--black);
    margin: 1rem;
}

.team-heading>h1 {
    font-size: 36px;
    letter-spacing: 1px;
    line-height: 1.1;
    font-weight: bold;
    margin: 1rem;
}

.team-boxs {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    height: auto;
}

.team-box {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    margin: 1rem;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    padding: 2vw 3%;
}

.tb-title>h3 {
    font-size: 26px;
    letter-spacing: 1px;
    line-height: 1;
    color: var(--black);
    margin: 10px 0;
    font-weight: 550 
}

.tb-desc>h5 {
    font-size: 16px;
    line-height: 28px;
    color: var(--gray);
    margin-bottom: 5%;
    width: 250px;
}

.tb-btn {
    border: 1px solid #1c72b8;
    color: #1c72b8;
}

.tb-btn:hover {
    background-color: #1c72b8;
    color: white;
}

.team-btn {
    margin: 1rem;
}

.team-btn>img {
    width: 100%;
    height: 100%;
}

.ceremony {
    display: flex;
    flex-direction: column;
    margin-top: 2%;
    width: 100%;
    height: 60vh;
    text-align: center;
}

.ceremony-title>h1 {
    font-size: 36px;
    letter-spacing: 1px;
    line-height: 1.1;
    font-weight: 600;
    margin: 2rem 1rem;
}

.ceremony-dec>h3 {
    font-size: 22px;
    line-height: 34px;
    font-weight: 300;
    color: var(--gray);
    margin-bottom: 5%;
}

.cermony-img {
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    width: 80%;
}

.cermony-img>img {
    width: 30%;
    height: 80%;
    margin: 1rem;
}

.Corporate {
    display: flex;
    flex-direction: column;
    margin-top: 10%;
    width: 100%;
    text-align: center;
}

.ceremony-dec {
    width: 80%;
}

.cb-title>h3 {
    font-weight: 550;
    text-align: center;
    width: 100%;
    line-height: 1.2;
    font-size: 21px;
}

.social-img{
    margin-left: 10%;
    margin-right: 10%;
    background: url(../../img/social.webp);
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    padding: 2.6%;
    margin-bottom: 100px;
    border-radius: 15px;
    background-position: center;
    margin-top: 5%;
    height: auto;
}
.social-header>h3{
    font-family: EurostileExt;
    font-size: 12px;
    letter-spacing: 2px;
    color: #fff;
    text-transform: uppercase;
    padding: 6% 0%;
}
.social-title>h1{
    font-size: 54px;
    letter-spacing: 2px;
    width: 100%;
    margin: 0;
    font-weight: 600;
    padding: 1% 0%;
}
.social-dec>h6{
    font-size: 20px;
    line-height: 34px;
    font-weight: 300;
    margin: 0;
}
.social-btn{
    margin-top: 5%;
}
