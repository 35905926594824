.Product-wrapper {
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
}

.p-topBox {
    width: 100%;
    height: auto;
    display: inline-block;
    background: #fff !important;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    border-radius: 8px;
    margin-bottom: 20px;
    margin-top: 7%;
    display: flex;
    justify-content: space-between;
    transition: box-shadow .3s;
    position: relative;

}
.p-topBox:hover{
    box-shadow: 0 0 40px rgb(0 0 0 / 20%);
}
.p-topBox-left {
    flex: 3;
}

.p-topBox-icon {
    margin: 2rem 1rem;
    margin-bottom: 20%;
}

.p-topBox-icon>img {
    height: 42px;
    max-width: 100%;
}
.productbg{
    position: absolute;
    top: 26%;
    left: 3%;

}
.productbg>img{
    width:100%;
}
.p-topBox-title>h3 {
    font-size: 54px;
    letter-spacing: 2px;
    line-height: 1.1;
    color: var(--black);
    margin: 0.5rem 1rem;
    font-weight: 550;
}

.p-topBox-dec>h6 {
    font-size: 20px;
    line-height: 34px;
    color: var(--gray);
    margin: 0.5em 1rem;
    margin-bottom: 30px;
    font-weight: 300;
    width: 100%;

}

.p-topBox-btn {
    margin: 1rem 1rem;
}

.p-topBox-btn>:nth-of-type(1) {
    color: var(--main);
    border: 1px solid var(--main);
    width: 155px;
    height: auto;
}

.p-topBox-btn>:nth-of-type(1):hover {
    background-color: var(--main);
    color: white;

}

.p-topBox-btn>:nth-of-type(2) {
    background-color: var(--main);
    width: 155px;
    height: auto;
}

.p-topBox-btn>:nth-of-type(2):hover {
    color: var(--main);
    border: 1px solid var(--main);
    background-color: white;
}

.p-topBox-right {
    flex: 1;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.p-topBox-img {
    display: flex;
    flex-direction: column;
    margin-right: 10%;
    max-width: 90px;

}
.p-topBox-img>img{
    display: block;
    width: 100%;
    max-width: 90px;
}
.p-topBox-img>span {
    color: #212121;
    text-align: center;
    font-size: 12px;
    letter-spacing: 2px;
    color: var(--gray);
    text-align: center;
    text-transform: uppercase;
    padding: 0 0 30px;
}

