.explore-wrapper {
    height: auto;
    width: 90%;
    margin-left: 10%;
    overflow: hidden;
}

.explore-l {
    width: 65%;
    height: auto;
    float: left;
}

.e-title {
    display: flex;
    flex-direction: column;
    color: white;
    gap: 1rem;
    margin-top: 10%;
}

.e-title>h2 {
    font-size: 54px;
    letter-spacing: 2px;
    line-height: 1.1;
    color: #212121;
}

.e-title>h2>span {
    font-size: 54px;
    letter-spacing: 2px;
    line-height: 1.1;
    color: #212121;
    font-weight: bold;
}

.e-title>h4 {
    font-size: 26px;
    letter-spacing: 1px;
    line-height: 1;
    color: #212121;
    margin-left: 0;
}

.e-chat {
    position: relative;
    width: 99%;
    height: auto;
    margin-top: 2%;
    border-bottom: 1px solid gray;
}

.e-chat>span {
    color: #fff;
    border: 1px solid #1c72b8;
    border-radius: 40px;
    background: #1c72b8;
    font-weight: lighter;
    float: left;
    border-top-left-radius: 0;
    font-size: 0.8rem;
    display: inline-block;
    padding: 10px 15px 8px;
    margin-top: 25%;
}

.explore-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.el-btn {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
    padding: 1rem;
    margin-left: 0;
}

.er-btn {
    margin-right: 0;
    width: 25%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    text-align: right;
}

.e-btn {
    font-size: 12px;
    background-color: transparent;
    border: 1px solid #1c72b8;
    color: #1c72b8;
    font-weight: 200;
    width: 100px;
    height: 35px;
    border-radius: 40px;
    margin: 0px 5px;
    transition: .3s all linear;
}

.e-btn:hover {
    background-color: rgb(224, 220, 220);
}

.ed-btn {
    font-size: 12px;
    background-color: transparent;
    border: 1px solid #1c72b8;
    color: #1c72b8;
    font-weight: 200;
    width: 100px;
    height: 35px;
    border-radius: 40px;
    margin: 0px 5px;
    transition: .3s all linear;
}

.ed-btn:hover {
    background-color: #4d98d4;
    color: white;

}

.explore-r {
    float: right;
    width: 35%;
    height: 100vh;
}

.er-mainBox {
    min-height: auto;
    width: 26vw;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    background: #fff;
    border-radius: 8px;
    margin-top: 10%;
    padding: 0.5rem 1rem;
}

.er-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: auto;
    border-bottom: 1px solid var(--gray);
    margin-top:10px;
}

.er-img {
    width: 20%;
}

.er-img>img {
    width: 70%;
}

.er-title {
    width: 80%;
}

.er-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.er-heading>div>h2 {
    font-size: 18px;
    letter-spacing: .5px;
    color: #212121;
    margin-bottom: 2px;
}

.er-heading>div>h4 {
    color: #7c7d7d;
    font-size: 12px;
    letter-spacing: .5px;
    margin-bottom: 5px;
}

.er-box>h6 {
    height: 36px;
    font-size: 13px;
    color: #7c7d7d;
    margin-bottom: 1.5rem;
    line-height: 20px;
    width: 100%;
}

.left-btn {
    font-size: 12px;
    background-color: transparent;
    border: 1px solid #1c72b8;
    color: #1c72b8;
    font-weight: 200;
    width: 100px;
    height: auto;
    border-radius: 40px;
    margin: 0px 5px;
    font-weight: bold; 
    transition: .3s all ease;
}
.er-btnleft{
    margin-left: 0;
    margin-bottom: 10px;
}
.left-btn:hover {
    background-color: #1c72b8;
    color: white;
}

