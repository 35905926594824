.a-explore{
    height: auto;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    margin-bottom: 7%;
}
.explore-box{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    margin: 1rem;
    background-color: white;
    width: 30%;
    padding: 2vw 2%;
}
.explore-header{
    width: 100%;
    display: flex;
    justify-content: space-around;

}
.explore-title{
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: 0;

}
.explore-title>h3{
    margin-left: 0;
    font-size: 18px;
    letter-spacing: 0.5px;
    font-weight: 600;
}
.explore-title>h4{
    margin-left: 0;
    font-size: 13px;
    color: #7c7d7d;
    letter-spacing: 0.5px;
    font-weight: 600;
}
.explore-desc{
    margin: 0.5rem 0rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
}
.explore-desc>h5{
    line-height: 28px;
    font-size: 15px;
    color: #abadad;
    font-weight: 200;
    width: 100%;
}
.explore-icon{
    z-index: 100;
    width: 55px;
}
.explore-btns{
    margin-left: 0;
    margin-top: 5px;
}
.ex-btn{
    border: 1px solid #1c72b8;
    color: #1c72b8;
}
.ex-btn:hover{
    background-color: #1c72b8;
    color: white;

}