.m-wrapper{
    background:  linear-gradient(to bottom right,#0f1012,#181b90);
    width: 100%;
    height: 100vh;
    display: flex;
}

.m-bottom{
    flex: 2;
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 0.5rem;
    margin-left: 10%;
}
.m-bottom>h1{
    color: #1c72b8;
    letter-spacing: 2px;
    line-height: 1.1;
    margin-top: 30%;
    margin-left: 0;
    font-weight: bold;
    font-size: 60px;
}
.m-bottom>h2{
    color: #fff;
    letter-spacing: 1;
    margin-left: 0;
    font-size: 36px;
}
.m-bottom>h3{
    line-height: 34px;
    color: white;
    margin-left: 0;
    font-weight: 100;
    font-size: 20px;
}
.m-btn{
    position: absolute;
    bottom:5%;
    left: 50%;
}
.m-right{
    flex: 1.5;
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: inherit;
}
.m-right>select{
    margin-right: 10%;
    margin-top: 6%;
    font-size: 12px;
    text-transform: capitalize;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;
    color: aliceblue;
}
.m-left>select>option{
    background-color: #181b90;
    font-size: 10px;
    color: #0f1012;
}


