.demo{
    position: absolute;
    bottom: 2%;
    right: 2%;
    position: fixed;
    z-index:50;
}
.demo-main{
    cursor: pointer;
}
.demo-main>img{
    width: 100%;
}
.demo-bigbox{
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.6);
    position: absolute;
    position: fixed;
    z-index: 89;
    display: flex;
    justify-content: center;
    align-items: center;
}
.demo-box{
    background-color: white;
    width: 60%;
    height: 90vh;
    border-radius: 10px;
    position: relative;
}
.demo-heading>h1{
    margin: 2rem 0.5rem;
    margin-bottom: 0%;
}
.demo-heading>h2{
    margin: 0rem 0.5rem;
}
.demo-items>form{
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
}
.demo-items>form>input{
    width: 95%;
    height: 35px;
    line-height: 35px;
    padding: 0;
    font-size: 20px;
    font-weight: 400;
    outline: none;
    margin: 0.5rem;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid var(--main);
    padding: 0.5rem;
    color: black;

}
.demo-items>form>select{
    width: 95%;
    height: 50px;
    line-height: 35px;
    padding: 0;
    font-size: 20px;
    font-weight: 400;
    outline: none;
    margin: 0.5rem;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid var(--main);
    padding: 0.5rem;
    color: black;
}
.demo-close{
    position: absolute;
    right:2%;
    top: 1%;
}
.demo-close>span{
    cursor: pointer;
}