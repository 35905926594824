.menuBar {
    padding: 3% 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 10%;
    height: 100vh;
    position: fixed;
}

.main_M>h1 {
    color: #1c72b8;
    text-transform: uppercase;
    font-family: 'Eurostile Extended', sans-serif;
    font-size: 11px;
    letter-spacing: 2px;
    margin-bottom: 5px;
    font-weight: 200;
}

.main_M>span {
    color: #1c72b8;
    font-weight: 100;
}

.main_M>span:hover {
    cursor: pointer;
}


.navbar_icon{
    display: flex;
    flex-direction: column;
    height: 15px;
    justify-content: space-between;
}
.menu1 ,
.menu2 ,
.menu3{
    width: 40px;
    height: 1px;
    background-color: #1c72b8;
    transition: all .3s;
}

.navbar_icon:hover .menu1{
    transform: rotate(45deg) translate(10px, 10px);
    transition: ease-out 0.5s;
}
.navbar_icon:hover .menu2{
    transform: scale(0.1);
    transition: ease-out 0.5s;
}
.navbar_icon:hover .menu3{
    transform: rotate(132deg) translate(0px, 0px);
    transition: ease-out 0.5s;
}

.gototop{
    display: flex;
    flex-direction: column;

}
.gototop>img{
    width: 90%;
}
.downicon{
    margin-top: -30px;
}
















/* .menu-btn{
    width: 40px;
    height: 2px;
    background-color: #1c72b8;
    transition: all .3s;
}
.menu-btn::before {
    content: '';
    position: absolute;
    width: 40px;
    height: 1.5px;
    background-color: #1c72b8;
    transition: all .3s;
}
.menu-btn::after{
    content: '';
    position: absolute;
    width: 40px;
    height: 1px;
    background-color: #1c72b8;
    transition: all .3s;
}
.menu-btn::before{
    transform: translateY(-5px);
}
.menu-btn::after{
    transform: translateY(-10px);
} */