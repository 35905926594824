.n_wrapper {
    width: 100%;
    height: 100vh;
    background: linear-gradient(to bottom right, #0f1012, #181b90);
    display: flex;
    flex-direction: column;
    gap: 5rem;
    z-index: 10;
    position: fixed;
}

.n-top {
    width: 100%;
    height: 20vh;
    text-align: center;
}

.n-logo {
    margin: 2rem 3rem;
    float: left;
}

.n-btn-right {
    margin: 4rem 3rem;  
    float: right;
}

.n-bottom{
    display: flex;
    justify-content:center;
    align-items: center;
    width: 100%;
    height: 30vh;
    color: white;
    position: relative;
}
.n-menu-btn{
    cursor: pointer;
    font-size: 10px;
    margin-left: 1.5%;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    width: 10%;
}
.n-menu-btn>:nth-child(1){
    font-size: 15px;
}
.n-menu{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin-right: 5rem;
    z-index: 100;

}
.n-menu>ul{
    list-style-type: none;
    display: flex;
    gap: 1rem;
}
.n-menu>ul>li{
    font-weight: 600;
    font-size: 38px;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    cursor: pointer;
    text-decoration: none;
    color: white;
}
.n-menu>ul>li>a{
    font-weight: 600;
    font-size: 38px;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    cursor: pointer;
    text-decoration: none;
    color: white;  
}
.n-menu>ul>li>span{
    color: #1c72b8;
    font-size: 15px;
    height: 10px;
    font-family: EurostileExt;
    margin-left: 1px;
}
/* .n-drop1:hover .n-dropdown{
    visibility: visible;
} */
.n-menu>ul>li>a:hover{
    color: #1c72b8;
}
.n-menu>ul>li:hover{
    color: #1c72b8;
}
.n-dropdown{
    list-style-type: none;
    overflow: hidden;
    visibility: hidden;
    margin: 0;
    padding: 0;
    color: white;
    /* position: absolute;
    margin-top: 3%; */

}
.n-dropdown>li:hover{
    color: #1c72b8;
}

.n-down-btn{
    margin-top: 30%;
    padding: 0;

}

.navbar-bgVector{
   width: 100%;
   height: 300px;
   display: flex;
   position: absolute;
   align-items: center;
   justify-content: center;
   text-align: center;
   z-index: -111;
}
.navbar-bgVector>img{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* 
/* 7c7d7d; */
/* font-family: AkkuratLight; * */