.footer-wrapper {
    width: 100%;
    height: fit-content;
    background: #fcfcfc;
}

.footer-top {
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    width: 80%;
    padding: 60px 0 0;
}

.footer-top1 {
    display: flex;
    flex-direction: column;
    width: 20%;
    height: fit-content;
    text-align: left;
    padding: 0;
    margin: 1rem 1rem;
    border-top: #d7d9da solid 1px;
    padding-top: 50px;
}

.footer-line {
    width: 97%;
    height: 1px;
    background-color: var(--black);
}

.footer-heading {
    margin: 0;
    padding-bottom: 15px;
}

.footer-heading>h2 {
    font-size: 12px;
    color: #212121;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: 'Eurostile Extended', sans-serif;
    font-weight: 500;

}

.footer-items {
    margin: 0;
}

.footer-items>ul {
    list-style-type: none;
}

.footer-items>ul>li {
    font-size: 14px;
    line-height: 28px;
    color: var(--gray);
    font-weight: 300;
}

.footer-items>ul>li>span {
    font-size: 14px;
    line-height: 28px;
    font-weight: 300;
}

.footer-down {
    margin-top: 50px;
    margin-left: 10%;
    margin-right: 10%;

}

.footer-icons>:nth-child(1),
.footer-icons>:nth-child(2) {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.footer-icons>div>h1 {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 28px;
    color: var(--gray);
    font-weight: 300;
}
.footer-icons>div>span{
    color: var(--gray);
}
.footerDownLine {
    border-top: #d7d9da solid 1px;
}

.footer-title {
    color: var(--gray);
    margin: 1rem 0rem;
    height: 5vh;
}

.footerDown-left>h2 {
    font-size: 15px;
    line-height: 22px;
    font-weight: 300;
    float: left;
}

.footerDown-right>h3 {
    font-size: 15px;
    line-height: 22px;
    font-weight: 300;
    float: right;
}